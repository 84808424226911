


























































import { defineComponent, reactive, onMounted } from "@vue/composition-api";
import { common } from "@/composable/common";
import CommonTemplate from "@/components/templates/CommonTemplate.vue";
import { globalConfirm } from "@/composable/globalConfirm";
import { MainRegistrationInfo } from "@/models";
import { filter } from "@/composable/utils/filter";
import { LiteAppliesService } from "@/openapi";
import { ApiError } from "@/openapi";
import store from "@/store";
import dayjs from "dayjs";

export default defineComponent({
  components: {
    CommonTemplate,
  },
  setup(props, context) {
    const { info, handleError } = common();
    const { confirm } = globalConfirm();
    const state = reactive<{
      mainRegistrationInfo: MainRegistrationInfo | null;
      epsilonError: string;
    }>({
      mainRegistrationInfo: null,
      epsilonError: "",
    });
    onMounted(async () => {
      try {
        info.dispSmallProgress();
        let token = context.root.$route.query["u"];
        if (!token || typeof token !== "string") {
          // 戻るボタンで戻った場合Token復元
          await store.dispatch.auth.restore();
          if (!store.getters.auth.token) {
            throw new Error("有料プラン申込にはユーザー登録が必要です。");
          }
          token = store.getters.auth.token;
        } else {
          store.dispatch.auth.token(token);
        }
        token = decodeURIComponent(token);
        state.mainRegistrationInfo = (await LiteAppliesService.getLiteAppliesService({
          token,
        })) as MainRegistrationInfo;
      } catch (e) {
        handleError(e, context.root.$router, "アカウント情報の取得に失敗しました。");
        if (!(e instanceof ApiError)) {
          context.root.$router.replace({ name: "register" });
        }
      }
      info.closeProgress();
    });
    const onRegister = async () => {
      if (!(await confirm("クレジットカード情報の入力に進みますか?"))) {
        return false;
      }
      try {
        info.dispSmallProgress();
        const orderUrl = await LiteAppliesService.postLiteAppliesService1({
          token: store.getters.auth.token as string,
        });
        location.href = orderUrl;
      } catch (e) {
        if (e instanceof ApiError && e.status === 406) {
          state.epsilonError = e.body;
        } else {
          handleError(e, context.root.$router, "クレジットカード登録処理に失敗しました。");
        }
      }
      info.closeProgress();
    };
    return { state, onRegister, dayjs, filter };
  },
});
