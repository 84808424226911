import { defineModule } from "direct-vuex";
import { moduleGetterContext, moduleActionContext } from "../index";
const KEY = "Fn2jB7ZV3Ahe6yJSEbNTZ5B5TfQKjpuHmtQcZNNK";

export interface AuthState {
  token: string | null;
}

const auth = defineModule({
  state: (): AuthState => {
    return {
      token: null,
    };
  },
  getters: {
    token: (...args): string | null => {
      const { state } = moduleGetterContext(args, auth);
      return state.token;
    },
  },
  mutations: {
    SET_TOKEN(state, token: string) {
      state.token = token;
    },
    REMOVE_TOKEN(state) {
      state.token = null;
    },
  },
  actions: {
    token(context, param: string) {
      const { commit } = moduleActionContext(context, auth);
      commit.SET_TOKEN(param);
      sessionStorage.setItem(KEY, param);
    },
    restore(context) {
      const lsData = sessionStorage.getItem(KEY);
      if (!lsData) {
        return false;
      }
      const { commit } = moduleActionContext(context, auth);
      commit.SET_TOKEN(lsData);
      return true;
    },
    clear(context) {
      sessionStorage.removeItem(KEY);
      const { commit } = moduleActionContext(context, auth);
      commit.REMOVE_TOKEN();
      return true;
    },
  },
  namespaced: true,
});

export default auth;
