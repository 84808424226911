



















































import { defineComponent, PropType } from "@vue/composition-api";
import TextInput from "@/components/atoms/TextInput.vue";
import CheckboxInput from "@/components/atoms/CheckboxInput.vue";
import { Registration } from "@/models";
import { rules } from "@/composable/rules";
import { EditMode } from "@/const";

export default defineComponent({
  components: {
    TextInput,
    CheckboxInput,
  },
  props: {
    formData: {
      type: Object as PropType<Registration>,
      required: true,
    },
  },
  setup() {
    return { rules, EditMode };
  },
});
