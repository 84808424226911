import Vue from "vue";
import Vuex from "vuex";
import { createDirectStore } from "direct-vuex";
import app from "./modules/app";
import auth from "./modules/auth";
Vue.use(Vuex);

const { store, rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext } = createDirectStore({
  modules: { app, auth },
});
export default store;
export { rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext };
export type AppStore = typeof store;
declare module "vuex" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    direct: AppStore;
  }
}
