export const themes = {
  light: {
    primary: "#005EAD",
    primaryAccent: "#0c7edb",
    primaryBg: "#EFF7FF",
    secondaryAccent: "#29B6F6",
    secondaryBg: "#e6f1f3",
    displayModeReadOnly: "#d1edff",
    displayModeEditing: "#dffcbf",
  },
};
