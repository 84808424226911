<template>
  <v-dialog v-model="dialog" :width="width" max-width="90%" @keydown.esc="cancel">
    <v-card>
      <v-card-title v-if="title || titleIcon"
        ><div class="d-flex align-center">
          <v-icon v-if="titleIcon" :color="titleIconColor" class="pr-1">{{ titleIcon }}</v-icon
          >{{ title }}
        </div></v-card-title
      >
      <v-card-text :class="title ? '' : 'py-4'">{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="isCancelable" depressed small color="grey lighten-2" data-test="cancel-btn" @click="cancel">{{
          cancelLabel
        }}</v-btn>
        <v-btn depressed small dark color="primaryAccent" data-test="ok-btn" @click="ok">{{ okLabel }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default {
  props: {
    message: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    okLabel: {
      type: String,
      default: "OK",
    },
    cancelLabel: {
      type: String,
      default: "キャンセル",
    },
    okAction: {
      type: Function,
      required: true,
    },
    cancelAction: {
      type: Function,
      required: true,
    },
    width: {
      type: String,
      default: "580",
    },
    isCancelable: {
      type: Boolean,
      default: true,
    },
    titleIcon: {
      type: String,
      default: "",
    },
    titleIconColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  created() {
    this.$mount();
    document.body.appendChild(this.$el);
    this.dialog = true;
  },
  methods: {
    ok() {
      this.okAction();
      this.destroy();
    },
    cancel() {
      this.cancelAction();
      this.destroy();
    },
    destroy() {
      this.dialog = false;
      setTimeout(() => {
        if (document.body.contains(this.$el)) document.body.removeChild(this.$el);
        this.$destroy();
      }, 200);
    },
  },
};
</script>
