import dayjs from "dayjs";
import { dateTimeUtil } from "@/composable/utils/dateTimeUtil";

export const rules = {
  required: (value: string): string | boolean => (value != null && value !== "") || "必須項目です",
  positiveInteger: (value: string): string | boolean =>
    value == null || (/^[0-9]+$/.test(value) && parseInt(value) > 0) || "1以上の整数を入力してください",
  integer: (value: string): string | boolean =>
    !value || (/^[0-9]+$/.test(value) && parseInt(value) >= 0) || "0以上の整数を入力してください",
  integerIncludeNegative: (value: string): string | boolean =>
    !value || (/^[-0-9]+$/.test(value) && !isNaN(parseInt(value))) || "整数を入力してください",
  number: (value: string): string | boolean =>
    !value || (/^[-0-9.]+$/.test(value) && !isNaN(Number(value))) || "数値を入力してください",
  decimal: (decimalPlace = 1) => {
    return (value: string | number | null): string | boolean => {
      if (!value || (/^[0-9]+$/.test(value.toString()) && !isNaN(Number(value)))) {
        return true;
      }
      if (/^[0-9]+\.[0-9]+$/.test(value.toString())) {
        const num = value.toString().split(".")[1];
        if (num != null && num.length <= decimalPlace) {
          return true;
        }
      }
      return `小数点以下${decimalPlace}桁までの数値を入力してください`;
    };
  },
  zipCode: (value: string): string | boolean => !value || /^\d{3}-?\d{4}$/.test(value) || "書式を確認してください",

  tel: (value: string): string | boolean =>
    // 連続しないハイフンを除いて0から始まる数値10-11桁
    !value || (!value.match(/-{2,}/g) && /^0\d{9,10}$/.test(value.replace(/-/g, ""))) || "書式を確認してください",

  email: (value: string): string | boolean =>
    !value ||
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(value) ||
    "書式を確認してください",
  max: (maxNum: number) => {
    return (value: string): string | boolean => !value || value.length <= maxNum || `最大文字数は${maxNum}文字です`;
  },
  min: (minNum: number) => {
    return (value: string): string | boolean => !value || value.length >= minNum || `最小文字数は${minNum}文字です`;
  },
  maxNumber: (maxNum: number | null | undefined) => {
    return (value: string): string | boolean =>
      value == null || maxNum == null || parseInt(value) <= maxNum || `最大値は${maxNum}です`;
  },
  minNumber: (minNum: number | null | undefined) => {
    return (value: string): string | boolean =>
      value == null || minNum == null || parseInt(value) >= minNum || `最小値は${minNum}です`;
  },
  maxMinutes: (maxMinutes: number | null | undefined) => {
    return (value: string): string | boolean =>
      value == null ||
      maxMinutes == null ||
      dateTimeUtil.str2minutes(value) <= maxMinutes ||
      `最大値は${dateTimeUtil.minutes2str(maxMinutes)}です`;
  },
  minMinutes: (minMinutes: number | null | undefined) => {
    return (value: string): string | boolean =>
      value == null ||
      minMinutes == null ||
      dateTimeUtil.str2minutes(value) >= minMinutes ||
      `最小値は${dateTimeUtil.minutes2str(minMinutes)}です`;
  },
  code: (value: string): string | boolean =>
    !value || /^[a-zA-Z0-9-_]+$/.test(value) || "使用できない文字が含まれています",
  password: (value: string): string | boolean =>
    // eslint-disable-next-line no-useless-escape
    !value || /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@^_`{|}~\[\]]+$/.test(value) || "使用できない文字が含まれています",

  beforeDate: (targetValue: string | null | undefined, targetName: string, sameOrBefore = true) => {
    return (value: string): string | boolean => {
      if (!value || !targetValue) {
        return true;
      }
      const currentDate = dayjs(value);
      const targetDate = dayjs(targetValue);
      if (
        !currentDate.isValid() ||
        !targetDate.isValid() ||
        currentDate.isBefore(targetDate) ||
        (sameOrBefore && currentDate.isSame(targetDate))
      ) {
        return true;
      }
      return `${targetName}より後の日付です`;
    };
  },
  afterDate: (targetValue: string | null | undefined, targetName: string, sameOrAfter = true) => {
    return (value: string): string | boolean => {
      if (!value || !targetValue) {
        return true;
      }
      const currentDate = dayjs(value);
      const targetDate = dayjs(targetValue);
      if (
        !currentDate.isValid() ||
        !targetDate.isValid() ||
        currentDate.isAfter(targetDate) ||
        (sameOrAfter && currentDate.isSame(targetDate))
      ) {
        return true;
      }
      return `${targetName}より前の日付です`;
    };
  },
  dateString: (value: string): string | boolean =>
    !value || /^(1|2)\d{3}[/-]?(0?[1-9]|1[0-2])[/-]?(0?[1-9]|[12][0-9]|3[01])$/.test(value) || "書式を確認してください",
  timeString: (value: string): string | boolean =>
    !value || dateTimeUtil.isTimeString(value, true) || "書式を確認してください",
  shortTimeString: (value: string): string | boolean =>
    !value || dateTimeUtil.isTimeString(value) || "書式を確認してください",
  shortTimeString24: (value: string): string | boolean =>
    !value || dateTimeUtil.isTimeString(value, false, true) || "書式を確認してください",

  beforeTime: (targetValue: string | number | null | undefined, targetName: string, sameOrBefore = true) => {
    return (value: string | number): string | boolean => {
      if (!value || !targetValue) {
        return true;
      }
      if (typeof value === "string") {
        value = dateTimeUtil.timeString(value);
        if (value === "") {
          return true;
        }
      }
      if (typeof targetValue === "string") {
        targetValue = dateTimeUtil.timeString(targetValue);
        if (targetValue === "") {
          return true;
        }
      }
      const currentTime = dateTimeUtil.str2minutes(value);
      const targetTime = dateTimeUtil.str2minutes(targetValue);
      if ((sameOrBefore && currentTime <= targetTime) || (!sameOrBefore && currentTime < targetTime)) {
        return true;
      }
      return `${targetName}より前の時刻を入力してください`;
    };
  },
  afterTime: (targetValue: string | number | null | undefined, targetName: string, sameOrAfter = true) => {
    return (value: string | number): string | boolean => {
      if (!value || !targetValue) {
        return true;
      }
      if (typeof value === "string") {
        value = dateTimeUtil.timeString(value);
        if (value === "") {
          return true;
        }
      }
      if (typeof targetValue === "string") {
        targetValue = dateTimeUtil.timeString(targetValue);
        if (targetValue === "") {
          return true;
        }
      }
      const currentTime = dateTimeUtil.str2minutes(value);
      const targetTime = dateTimeUtil.str2minutes(targetValue);
      if ((sameOrAfter && currentTime >= targetTime) || (!sameOrAfter && currentTime > targetTime)) {
        return true;
      }
      return `${targetName}より後の時刻を入力してください`;
    };
  },
  withinMonth: (targetDate: string) => {
    return (value: string): string | boolean => {
      if (!value) {
        return true;
      }
      if (dayjs(value).isSame(dayjs(targetDate), "month")) {
        return true;
      }
      return `${dayjs(targetDate).month() + 1}月の日付を指定してください`;
    };
  },
  beforeGetsudo: (targetValue: number | null | undefined, targetName: string, sameOrBefore = true) => {
    return (value: string): string | boolean => {
      const v = Number(value.split("-").join(""));
      if (!v || !targetValue || isNaN(v)) {
        return true;
      }
      if (v < targetValue || (sameOrBefore && v <= targetValue)) {
        return true;
      }
      return `${targetName}より後の月度です`;
    };
  },
  afterGetsudo: (targetValue: number | null | undefined, targetName: string, sameOrAfter = true) => {
    return (value: string): string | boolean => {
      const v = Number(value.split("-").join(""));
      if (!v || !targetValue || isNaN(v)) {
        return true;
      }
      if (v > targetValue || (sameOrAfter && v >= targetValue)) {
        return true;
      }
      return `${targetName}より前の月度です`;
    };
  },
  maxGetsudoRange: (max: number, targetValue: number | null | undefined) => {
    return (value: string): string | boolean => {
      const v = Number(value.split("-").join(""));
      if (!v || !targetValue || isNaN(v)) {
        return true;
      }
      const v1 = dateTimeUtil.getsudo2dayjs(v);
      const v2 = dateTimeUtil.getsudo2dayjs(targetValue);
      if (!v1 || !v2) {
        return true;
      }
      if (Math.abs(v1.diff(v2, "month")) <= max) {
        return true;
      }
      return `${max}ヶ月を超える範囲は指定できません`;
    };
  },
  decimalHours: (value: string): string | boolean =>
    !value || /^([1-9][0-9]{0,2}|0)(\.[0-9]{1,2})?$/.test(value) || "整数部3桁、小数点2桁までの数値を入力してください",

  ngWords: (words: string[]) => {
    return (value: string): string | boolean => {
      if (value) {
        const ngList = words.filter((x) => value.match(x));
        if (ngList.length > 0) {
          return `${ngList[0]}は入力できません。`;
        }
      }
      return true;
    };
  },
  noSpace: (value: string): string | boolean => !value || !/\s/.test(value) || "スペースは入力できません",
  expression: (value: string): string | boolean =>
    !value || !/[\s+-/*())]/.test(value) || "使用できない文字が含まれています。",
  noNumber: (value: string): string | boolean =>
    !value || !(/^[0-9.]+$/.test(value) && !isNaN(Number(value))) || "数値は入力できません",
};
