


































import { defineComponent, reactive, ref } from "@vue/composition-api";
import { common } from "@/composable/common";
import { globalConfirm } from "@/composable/globalConfirm";
import CommonTemplate from "@/components/templates/CommonTemplate.vue";
import RegisterForm from "@/components/organisms/RegisterForm.vue";
import { Registration } from "@/models";
import { VForm } from "@/types/vuetify/vForm";
import { rules } from "@/composable/rules";
import { EditMode } from "@/const";
import { LiteAppliesService, RequestLiteFreeApplyParam } from "@/openapi";

export default defineComponent({
  components: {
    CommonTemplate,
    RegisterForm,
  },
  setup(props, context) {
    const { info, handleError } = common();
    const { confirm } = globalConfirm();
    const state = reactive<{
      formData: Registration | null;
      validationMessage: string;
      isRegistered: boolean;
    }>({
      formData: {
        name: "",
        tantosha: null,
        email: null,
      },
      validationMessage: "",
      isRegistered: false,
    });
    const form = ref<VForm>();
    const onRegister = async () => {
      if (state.formData) {
        if (!form.value?.validate()) {
          state.validationMessage = "入力内容を確認してください。";
          return;
        }
        if (!(await confirm("入力いただいた内容で登録してよろしいですか?"))) {
          return;
        }
        try {
          info.dispSmallProgress();
          await LiteAppliesService.postLiteAppliesService({
            requestBody: state.formData as RequestLiteFreeApplyParam,
          });
          state.isRegistered = true;
        } catch (e) {
          handleError(e, context.root.$router, "登録に失敗しました。");
        }
        info.closeProgress();
      }
    };
    return { state, form, rules, onRegister, EditMode };
  },
});
