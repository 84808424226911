import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Register from "@/views/Register.vue";
import MainRegister from "@/views/MainRegister.vue";
import MainRegisterCompleted from "@/views/MainRegisterCompleted.vue";
import Tokusho from "@/views/Tokusho.vue";
import { SiteSetting } from "@/env";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: { name: "register" },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: { title: "無料お申し込み" },
  },
  {
    path: "/tokusho",
    name: "tokusho",
    component: Tokusho,
    meta: { title: "特定商取引法に基づく表示" },
  },
  {
    path: "/mainregister",
    name: "mainregister",
    component: MainRegister,
    meta: { title: "有料お申し込み" },
  },
  {
    path: "/completed",
    name: "completed",
    component: MainRegisterCompleted,
    meta: { title: "決済処理完了" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  try {
    if (to.matched.length === 0) {
      throw new Error("未設定のルート");
    }
  } catch (e) {
    next({ name: "register" });
  }
  next();
});
router.afterEach((to) => {
  if (to.meta?.title) {
    document.title = SiteSetting.name + to.meta.title + " | 勤怠ドライバー ライト版";
  }
});
export default router;
