





























































import { defineComponent } from "@vue/composition-api";
import CommonTemplate from "@/components/templates/CommonTemplate.vue";

export default defineComponent({
  components: {
    CommonTemplate,
  },
  setup() {
    const onPrev = () => {
      history.back();
    };
    return { onPrev };
  },
});
