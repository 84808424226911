

































import { defineComponent, reactive, onMounted, computed } from "@vue/composition-api";
import { common } from "@/composable/common";
import CommonTemplate from "@/components/templates/CommonTemplate.vue";
import { LiteAppliesService } from "@/openapi";
import { ApiError } from "@/openapi";
import { SiteSetting } from "@/env";
import store from "@/store";

export default defineComponent({
  components: {
    CommonTemplate,
  },
  setup(props, context) {
    const { info } = common();
    const state = reactive<{
      isCompleted: boolean;
      transactionCode: string | null;
      error: RegisterError | null;
    }>({
      isCompleted: false,
      transactionCode: null,
      error: null,
    });
    enum RegisterError {
      EPSILON,
      AUTH,
      API,
    }
    onMounted(async () => {
      try {
        info.dispSmallProgress();
        const transactionCode = context.root.$route.query["trans_code"];
        const companyId = context.root.$route.query["user_id"];
        const result = context.root.$route.query["result"];
        if (!transactionCode || !companyId) {
          // 不正アクセス
          context.root.$router.replace({ name: "register" });
          info.closeProgress();
          return;
        }
        if (result !== "1") {
          // イプシロン決済失敗
          state.error = RegisterError.EPSILON;
          throw new Error("イプシロン決済失敗");
        }
        state.transactionCode = transactionCode as string;
        if (!(await store.dispatch.auth.restore())) {
          // Token復元失敗
          state.error = RegisterError.AUTH;
          throw new Error("Token復元失敗");
        }
        await LiteAppliesService.putLiteAppliesService({
          token: store.getters.auth.token as string,
          requestBody: {
            transactionCode: transactionCode as string,
          },
        });
        store.dispatch.auth.clear();
        state.isCompleted = true;
      } catch (e) {
        if (e instanceof ApiError) {
          state.error = RegisterError.API;
          store.dispatch.auth.clear();
        }
      }
      info.closeProgress();
    });
    const moveToKDCore = () => {
      location.href = SiteSetting.kdcoreUrl;
    };
    const isError = computed(() => {
      return state.error != null;
    });
    const isEpsilonError = computed(() => {
      return state.error === RegisterError.EPSILON;
    });
    return { state, isError, isEpsilonError, moveToKDCore };
  },
});
