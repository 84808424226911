import dayjs from "dayjs";

const regTime = /^([0123]?[0-9]|[4][0-7]):?([0-5][0-9]):([0-5][0-9])$/;
const regTime24 = /^([01]?[0-9]|[2][0-3]):?([0-5][0-9]):([0-5][0-9])$/;
const regTimeNoHours = /^([0-5]?[0-9]):?([0-5][0-9])$/;
const regShortTime = /^([0123]?[0-9]|[4][0-7]):?([0-5][0-9])$/;
const regShortTime24 = /^([01]?[0-9]|[2][0-3]):?([0-5][0-9])$/;
const regShortTimeNoHours = /^([0-5]?[0-9])$/;

export const dateTimeUtil = {
  isTimeString(value: string, seconds = false, is24hours = false): boolean {
    if (!is24hours) {
      if (seconds) {
        return regTime.test(value) || regTimeNoHours.test(value);
      } else {
        return regShortTime.test(value) || regShortTimeNoHours.test(value);
      }
    } else {
      if (seconds) {
        return regTime24.test(value) || regTimeNoHours.test(value);
      } else {
        return regShortTime24.test(value) || regShortTimeNoHours.test(value);
      }
    }
  },
  timeString: (value: string, seconds = false, minus = false): string => {
    let v = "";
    let isMinusValue = false;
    if (value.charAt(0) === "-") {
      value = value.slice(1);
      isMinusValue = true;
    }
    if (seconds) {
      if (value.match(regTime)) {
        v = value.replace(regTime, "$1:$2:$3");
      } else if (value.match(regTimeNoHours)) {
        v = value.replace(regTimeNoHours, "$1:$2");
      }
    } else {
      if (value.match(regShortTime)) {
        v = value.replace(regShortTime, "$1:$2");
      } else if (value?.match(regShortTimeNoHours)) {
        v = value.replace(regShortTimeNoHours, "00:$1");
      }
    }

    if (v) {
      if (isMinusValue) {
        if (minus) {
          v = "-" + minus;
        } else {
          v = "0:0";
        }
      }
      const arr = v.split(":").map((x) => x.padStart(2, "0"));
      if (seconds && arr.length === 2) {
        arr.push("00");
      }
      return arr.join(":");
    }
    return "";
  },
  timeDiff(t1: string, t2: string): string {
    return this.minutes2str(this.str2minutes(t2) - this.str2minutes(t1));
  },
  str2minutes(val: string | number): number {
    if (typeof val === "number") {
      if (!isNaN(val)) {
        return val;
      }
    } else if (val.match(":")) {
      const arr = val.split(":");
      return parseInt(arr[0]) * 60 + parseInt(arr[1]);
    }
    return 0;
  },
  str2minutesOrDefault(val: string | null | undefined): number | undefined {
    return val ? this.str2minutes(val) : undefined;
  },
  minutes2strOrDefault(val: number | string | null | undefined, defaultString = ""): string {
    return val != null ? this.minutes2str(val) : defaultString;
  },
  minutes2str(val: number | string): string {
    if (val == null || val === "") {
      return "";
    }
    let innerVal = 0;
    if (val === 0) {
      return "00:00";
    } else if (typeof val === "number" && !isNaN(val)) {
      innerVal = val;
    } else if (typeof val === "string" && !isNaN(Number(val))) {
      innerVal = Number(val);
    }
    let isMinus = false;
    if (innerVal < 0) {
      isMinus = true;
      innerVal = Math.abs(innerVal);
    }
    const HH = Math.floor(innerVal / 60)
      .toString()
      .padStart(2, "0");
    const MM = (innerVal % 60).toString().padStart(2, "0");
    if (isMinus) {
      return `-${HH}:${MM}`;
    }
    return `${HH}:${MM}`;
  },
  minutes2hours(val: number | null | undefined): number {
    if (!val) {
      return 0;
    }
    return val / 60;
  },
  hours2minutes(val: number | null | undefined): number {
    if (!val) {
      return 0;
    }
    return val * 60;
  },
  nichiji2timeStrOrDefault(val: string | null | undefined, currentDate: string): string {
    return val != null && val !== "" && currentDate ? this.nichiji2timeStr(val, currentDate) : "";
  },
  nichiji2timeStr(val: string | null | undefined, currentDate: string): string {
    if (!val) {
      return "";
    }
    const target = dayjs(val);
    if (target.diff(currentDate, "day") === 1) {
      // 翌日の日付の場合、25時以降の表記に
      const HH = target.hour() + 24;
      return `${HH}:${target.format("mm")}`;
    } else {
      return target.format("HH:mm");
    }
  },
  minutes2nichiji(date: string, minutes: number): string {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    return dayjs(date).hour(h).minute(m).format("YYYY-MM-DDTHH:mm:ss");
  },
  dateTimeDiff(startDateTime: string | null | undefined, endDateTime: string | null | undefined): number {
    if (startDateTime == null || endDateTime == null) {
      return 0;
    }
    const start = dayjs(startDateTime).second(0).millisecond(0);
    const end = dayjs(endDateTime).second(0).millisecond(0);
    return start.diff(end, "minute");
  },
  getsudo2String(value: number | null, format = "YYYY-MM-DD"): string {
    if (value == null) {
      return "";
    }
    return this.getsudo2dayjs(value)?.format(format) ?? "";
  },
  getsudoString2getsudo(value: string): number | null {
    if (value.match(/^(1|2)\d{3}-(0?[1-9]|1[0-2])$/)) {
      const arr = value.split("-");
      return this.dateString2getsudo(`${arr[0]}-${arr[1]}-1`);
    } else if (value.match(/^(1|2)\d{3}(0[1-9]|1[0-2])$/)) {
      return this.dateString2getsudo(`${value}01`);
    }
    return null;
  },
  dateString2getsudo(date: string): number | null {
    const d = dayjs(date);
    if (d.isValid()) {
      return parseInt(d.format("YYYYMM"));
    }
    return null;
  },
  yearMonth2getsudo(year: number, month: number): number {
    return parseInt(dayjs(`${year}-${month}-1`).format("YYYYMM"));
  },
  getsudo2dayjs(value: number): dayjs.Dayjs | null {
    const s = value.toString();
    if (s.length === 6) {
      const year = parseInt(s.slice(0, 4));
      const month = parseInt(s.slice(-2));
      return dayjs(`${year}-${month}-1`);
    }
    return null;
  },
  dateString(date: string | null | undefined, defaultString = ""): string {
    if (!date) {
      return defaultString;
    }
    return dayjs(date).format("YYYY-MM-DD");
  },
  dateTimeString(date: string | null | undefined, seconds = false): string {
    if (!date) {
      return "";
    }
    if (seconds) {
      return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    }
    return dayjs(date).format("YYYY-MM-DD HH:mm");
  },
  nissuString(nissu: number | null | undefined, defaultString = ""): string {
    if (nissu == null) {
      return defaultString;
    }
    return `${nissu}日`;
  },
  dayString(date: string | null | undefined, defaultString = ""): string {
    if (date == null) {
      return defaultString;
    }
    return dayjs(date).format("ddd");
  },
  isBeforeToday(date: string | null | undefined): boolean {
    if (date && dayjs(date).isBefore(dayjs(), "day")) {
      return true;
    }
    return false;
  },
};
