/* eslint-disable @typescript-eslint/no-var-requires */

import { OpenAPI } from "@/openapi";
export const API_BASE = OpenAPI.BASE;

export const SiteSetting = require(`@/data/site.${process.env.NODE_ENV}.ts`).default as SiteSettings;

export const API = OpenAPI;
export class SiteSettings {
  name!: string;
  baseUrl!: string;
  kdcoreUrl!: string;
  deployFolder!: string;
  baseApiUrl!: string;
  applicationInsightsKey!: string;
  gtmId!: string;
}
