


























import { defineComponent, computed, watch, reactive } from "@vue/composition-api";
import store from "./store";

export default defineComponent({
  name: "App",

  setup() {
    const state = reactive<{
      showBg: boolean;
    }>({
      showBg: false,
    });
    const appState = computed(() => {
      return store.state.app;
    });

    let timerId: number | null = null;

    const dispSmallLoading = computed(() => store.state.app.dispSmallLoading);
    watch(dispSmallLoading, () => {
      if (dispSmallLoading.value) {
        timerId = window.setTimeout(() => {
          state.showBg = true;
        }, 1000);
      } else {
        state.showBg = false;
        if (timerId) {
          clearTimeout(timerId);
          timerId = null;
        }
      }
    });

    const closeSnackbar = () => {
      store.dispatch.app.closeSnackbar();
    };
    return {
      appState,
      state,
      dispSmallLoading,
      closeSnackbar,
    };
  },
});
