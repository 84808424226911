

























import { defineComponent, computed, PropType } from "@vue/composition-api";
import { InputValidationRule } from "vuetify";
import { EditMode } from "@/const";

export default defineComponent({
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    originalValue: {
      type: [String, Number],
    },
    editMode: {
      type: Number as PropType<EditMode>,
      default: EditMode.Read,
    },
    label: {
      type: String,
      default: "",
    },
    prependIcon: {
      type: String,
      default: "",
    },
    appendIcon: {
      type: String,
      default: "",
    },
    cssClass: {
      type: String,
      default: "",
    },
    passwordMask: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    maxlength: {
      type: Number,
      default: null,
    },
    rules: {
      type: Array as PropType<InputValidationRule[]>,
      default: () => [],
    },
  },
  setup(props, context) {
    const innerValue = computed({
      get: () => props.value,
      set: (value: string | number) => {
        if (value === "") {
          context.emit("input", null);
        } else {
          context.emit("input", value);
        }
        context.emit("change");
      },
    });
    const bgColor = computed(() => {
      if (props.editMode === EditMode.Edit && props.originalValue !== undefined) {
        if (props.value !== props.originalValue) {
          return "blue lighten-5";
        }
      }
      return "";
    });
    const readOnly = computed(() => props.editMode === EditMode.Read);
    return { innerValue, bgColor, readOnly };
  },
});
