export const filter = {
  defaultString: (value: string | number | null | undefined, defaultValue = ""): string | number => {
    if (value == null || value === "") {
      return defaultValue;
    }
    return value;
  },
  unitString: (
    value: string | number | null | undefined,
    unit = "",
    defaultValue: string | number = "",
    nospace = false
  ): string => {
    if (value == null || value === "") {
      if (typeof defaultValue === "number") {
        if (nospace) {
          return `${defaultValue}${unit}`;
        }
        return `${defaultValue} ${unit}`;
      }
      return defaultValue;
    }
    if (nospace) {
      return `${value}${unit}`;
    }
    return `${value} ${unit}`;
  },
  hanteiString: (value: boolean | null | undefined): string => {
    return value ? "〇" : "×";
  },
  amount: (
    value: string | number | null | undefined,
    unit = "",
    defaultValue: string | number = "",
    nospace = false
  ): string => {
    if (value == null || value === "") {
      if (typeof defaultValue === "number") {
        if (nospace) {
          return `${defaultValue}${unit}`;
        }
        return `${defaultValue} ${unit}`;
      }
      return defaultValue;
    }
    value = value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    if (nospace) {
      return `${value}${unit}`;
    }
    return `${value} ${unit}`;
  },
};
