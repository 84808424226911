/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Confirm from "@/components/molecules/GlobalConfirm.vue";
import Vue from "vue";
import Vuetify from "vuetify";
import { themes } from "@/plugins/vuetifyThemes";

export const globalConfirm = () => {
  const confirm = async (
    message: string,
    options?: {
      title?: string;
      width?: string;
      okLabel?: string;
      cancelLabel?: string;
      isCancelable?: boolean;
      titleIcon?: string;
      titleIconColor?: string;
    }
  ) => {
    return new Promise((resolve) => {
      Vue.use(Vuetify);
      const VM = Vue.extend(Confirm);
      new VM({
        parent: this,
        propsData: {
          message,
          ...options,
          okAction: () => resolve(true),
          cancelAction: () => resolve(false),
        },
        vuetify: new Vuetify({
          theme: {
            options: {
              customProperties: true,
            },
            themes,
          },
        }),
      });
    }).catch((err) => {
      throw err;
    });
  };
  const confirmCancelEdit = async (isEditing: boolean) => {
    if (isEditing && !(await confirm("編集中です。編集内容が破棄されますがよろしいですか?"))) {
      return false;
    }
    return true;
  };
  return { confirm, confirmCancelEdit };
};
