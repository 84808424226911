/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { computed } from "@vue/composition-api";
import store from "@/store";
import VueRouter from "vue-router";
import { ApiError } from "@/openapi";

export const common = () => {
  // 共通エラー処理
  const handleError = (
    e: Error,
    router: VueRouter,
    message: string,
    httpStatusMessages: { status: number; message: string }[] = []
  ) => {
    if (e instanceof ApiError) {
      message = httpStatusMessages.find((x) => x.status === e.status)?.message ?? message;
      if (e.status === 401) {
        message = "認証に失敗しました。";
      }
    }
    info.dispError(message);
  };

  const info = {
    dispError: (message: string) => {
      store.dispatch.app.dispError(message);
    },
    dispSuccess: (message: string) => {
      store.dispatch.app.dispSuccess(message);
    },
    closeProgress: () => {
      store.dispatch.app.closeProgress();
    },
    dispLargeProgress: (message: string | null = null) => {
      store.dispatch.app.dispLargeProgress(message);
    },
    dispSmallProgress: (message: string | null = null) => {
      store.dispatch.app.dispSmallProgress(message);
    },
    isLoading: computed(() => {
      return store.getters.app.isLoading;
    }),
  };
  return { info, handleError };
};
